
import { defineComponent, onMounted, PropType, watch } from 'vue'
import Tag from './Tag.vue'
import { Tag as ITag } from '@/types/main'
import useRouter from '../../../hooks/useRouter'
import qs from 'qs'
export default defineComponent({
    components: { Tag },
    props: {
        items: {
            type: Array as PropType<ITag[]>,
            required: true,
        },
        value: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        useCheckmark: {
            type: Boolean,
            default: true,
        },
        saveInQuery: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { router, route } = useRouter()

        const getColor = (tag: ITag) => {
            return tag.color ?? '#e0e0e0'
        }
        const update = (val: string[]) => {
            emit('input', val)
        }
        const selectTag = (tag: ITag) => {
            if (props.value.includes(tag.id)) {
                update(props.value.filter(el => el != tag.id))
            } else {
                update(props.value.concat(tag.id))
            }
        }
        const isTagSelected = (tag: ITag) => {
            return props.value.includes(tag.id)
        }

        onMounted(() => {
            if (route.value.query.tags && props.saveInQuery) {
                const tagIds = Object.values(qs.parse(route.value.query.tags as any))
                update(tagIds as string[])
            }
        })
        if (props.saveInQuery) {
            watch(
                () => props.value,
                () => {
                    router.push({
                        path: route.value.path,
                        query: Object.assign({}, route.value.query, { tags: qs.stringify(props.value) }),
                    })
                }
            )
        }

        return { getColor, selectTag, isTagSelected }
    },
})
