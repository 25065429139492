
import { defineComponent, PropType } from 'vue'
import { Tag } from '@/types/main'
import _ from 'lodash'
export default defineComponent({
    props: {
        color: {
            type: String,
            required: true,
        },
        selected: {
            type: Boolean,
        },
        item: {
            type: Object as PropType<Tag>,
            required: true,
        },
        useCheckmark: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const selectTag = () => {
            emit('select-tag', props.item)
        }
        return {
            selectTag,
            isNil: _.isNil,
        }
    },
})
